<template>
  <Layout>
   <div
      class="page-title-box d-flex align-items-center justify-content-between"
    >
      <h4 class="mb-0">Member Social links & Languages</h4>
      <a @click="$router.go(-1)" style="cursor: pointer; color: grey"
        >Back</a
      >
    </div>
    <div class="row" id="video">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mb-4">
              <div class="col-md-6"></div>
            </div>
            <div class="col-12">
              <b-form enctype="multipart/form-data" ref="email">
                <section class="d-flex align-items-center">
                  <label class="col-2">Language :</label>
                  <b-form-group
                    id="input-group-1"
                    label-for="input-1"
                    class="col-10"
                  >
                    <b-form-input
                      id="input-1"
                      placeholder="separate language with comma"
                      v-model="social_links_form.languages"
                    ></b-form-input>
                  </b-form-group>
                </section>
                <section class="d-flex align-items-center">
                  <label class="col-2">Custom Badge Icon :</label>
                  <b-form-group id="input-group-icon" label-for="input-icon" class="col-10">
                    <b-form-file
                        id="input-icon"
                        accept="image/*"
                        @change="readFile($event, 'custom_badge_icon')"
                        ref="custom_badge_icon"
                        placeholder="Choose a file [Upload Max File Size : 2MB]"
                      >
                    </b-form-file>
                    <template v-if="edit.custom_badge_icon_url">
                      <img :src="edit.custom_badge_icon_url" width="128px" height="128px" style="object-fit: contain; margin-top: 5px" />
                    </template>
                  </b-form-group>
                </section>
                <section
                  class="d-flex align-items-center"
                  v-for="(item, index) in social_links"
                  :key="index"
                >
                  <label class="col-2">{{ item.name }} :</label>
                  <b-form-group
                    :id="'input-group-' + index"
                    :label-for="'input-' + index"
                    class="col-10"
                  >
                    <b-form-input
                      :id="'input-' + index"
                      placeholder="Enter link"
                      v-model="social_links_form[item.value]"
                    ></b-form-input>
                  </b-form-group>
                </section>
                <div class="col-12">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mr-2"
                    @click.prevent="submitSocialLinks"
                    >Submit Data
                  </b-button>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import MixinRequest from "../../../mixins/request";
import member_mixin from "../../../mixins/ModuleJs/member-social-link";

export default {
  mixins: [MixinRequest, member_mixin],
  data() {
    return {
      items: [
        {
          text: "Back",
          href: "/member-list",
        },
        {
          text: "List",
        },
      ],
    };
  },

  components: {
    Layout,
  },
};
</script>
